<template>
  <div>
    <div class="iq-style4 mb-5" v-if="addresses.length > 0">
      <div v-for="(address, ind) in addresses" :key="ind" class="text-initial">
        <button
            class="btn address-radio-option h-100 w-100"
            :class="selectedAddress === address.id? 'address-radio-selected' : ''"
            @click.prevent="selectAddress(address)"
        >
          <b-row>
            <b-col lg="">
              <b-row class="text-initial">
              <b-col lg="12">
                <p><i class="text-warning las la-map-marker-alt pr-1"></i>
                  {{ address.street }} {{address.region ? address.region.name : '' }} {{address.city?address.city.name : ''}}
                </p>
              </b-col>
              <b-col>
                <p><i class="text-warning las la-info-circle pr-2"></i>
                  {{ $t('forms.block_no')}} {{address.block_no}} ,  {{ $t('forms.flat_no')}} {{address.flat_no}} , {{ $t('forms.floor_no')}} {{address.floor_no}}
                </p>
              </b-col>
              <b-col lg="12">
                <p><i class="text-warning las la-phone pr-2"></i>{{address.phone}}
                  <span v-if="address.phone2">{{` | ${address.phone2}`}}
                          </span></p>
              </b-col>
            </b-row>
            </b-col>
            <b-col lg="2">
              <img v-if="selectedAddress === address.id" :src="selected"/>
            </b-col>
          </b-row>
        </button>
      </div>
    </div>
    <div v-else class="iq-style4 mb-5">
      <h5 class="p-2 text-center text-white">{{ $t('profile.noAddress') }}</h5>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'

export default {
  data () {
    return {
      selectedAddress: '',
      addresses: [],
      selected: require('@/assets/images/curva/address-selected.png')
    }
  },
  methods: {
    selectAddress (value) {
      console.log('vale => ')
      this.selectedAddress = value.id
      this.$store.commit('setAddress', this.selectedAddress)
      this.$store.commit('updateDeliveryFees', value.region.shipping_cost)
    }
  },
  mounted () {
    core.index()
  },
  created () {
    ProfileServices.getAddress().then(res => {
      this.addresses = res.data.data
    })
    this.$store.commit('cartStep', 2)
  }
}
</script>
